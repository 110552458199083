/* purgecss start ignore */
@import './react-grid-layout.css';
@import './react-toastify.css';

@tailwind base;
@tailwind components;
/* purgecss end ignore */

@tailwind utilities;

@font-face {
  font-family: 'Grifter';
  src: url('/fonts/grifterbold.otf');
}

@font-face {
  font-family: 'Jakarta';
  src: url('/fonts/PlusJakartaSans-Regular.woff');
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background: #c4b5fd; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #c4b5fd; /* Gecko Browsers */
}

/* TODO disable grifter placeholder? */
::placeholder {
  font-family: 'Grifter';
}

@layer base {
  .markdown a {
    @apply text-primary-light-mode-main dark:text-neon-yellow-500 font-bold hover:underline;
  }

  :root {
    /* white-70 */
    --scrollbar-track-color: #b2b2b2;
    /* white-40 */
    --scrollbar-thumb-color: #666666;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .dark {
    /* white-10% */
    --scrollbar-track-color: #191919;
    /* white-25% */
    --scrollbar-thumb-color: #404040;
  }

  ::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: var(--scrollbar-track-color);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: var(--scrollbar-thumb-color);
  }

  input[type='text']:focus {
    box-shadow: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .scrollbar-hide {
    /* IE and Edge */
    -ms-overflow-style: none;

    /* firefox */
    scrollbar-width: none;
  }

  /* safari and chrome */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
}

@layer components {
  .v2-page-container {
    min-height: calc(100vh - 160px);
  }
}
